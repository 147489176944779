var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.showRecoveryPhrase)?_c('v-layout',{attrs:{"column":"","align-center":"","justify-center":"","mb-12":""}},[_c('v-flex',{staticClass:"text-left"},[_c('h2',{staticClass:"text-uppercase text-center mt-12 mb-2"},[_vm._v("\n        "+_vm._s(_vm.$t('components.newWallet.displayRecoveryPhrase.important'))+"\n      ")]),_vm._v(" "),_c('p',[_vm._v("\n        "+_vm._s(_vm.$t('components.newWallet.displayRecoveryPhrase.onlyBackup'))+"\n      ")]),_vm._v(" "),_c('p',[_vm._v("\n        "+_vm._s(_vm.$t('components.newWallet.displayRecoveryPhrase.writeItDown'))+"\n        "),_c('span',{staticClass:"text-capitalize"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'components.newWallet.displayRecoveryPhrase.responsiblityLine1.string'
            ))+"\n        ")]),_vm._v("\n        "+_vm._s(_vm.$t(
            'components.newWallet.displayRecoveryPhrase.responsiblityLine2.string'
          ))+"\n        "),_c('span',{staticClass:"dialog-link",on:{"click":function($event){_vm.showInstructionsDialog = true}}},[_vm._v("\n          "+_vm._s(_vm.$t(
              'components.newWallet.displayRecoveryPhrase.responsiblityLine3.string'
            ))+"\n        ")])])]),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.showInstructionsDialog),callback:function ($$v) {_vm.showInstructionsDialog=$$v},expression:"showInstructionsDialog"}},[_c('v-card',{staticClass:"pa-6",attrs:{"color":"modalBackground"}},[_c('h3',{staticClass:"text-center pt-3 white--text"},[_vm._v("\n          "+_vm._s(_vm.$t('components.newWallet.displayRecoveryPhrase.instructions'))+"\n        ")]),_vm._v(" "),_c('v-card-text',[_c('p',{staticClass:"white--text"},[_vm._v("\n            "+_vm._s(_vm.$t(
                'components.newWallet.displayRecoveryPhrase.recoveryPhraseAlert'
              ))+"\n          ")]),_vm._v(" "),_vm._l((_vm.instructions),function(item,index){return _c('v-layout',{key:index,attrs:{"align-start":""}},[_c('v-icon',{staticClass:"text--lighten-1 pt-1",attrs:{"color":item.color,"small":""}},[_vm._v("\n              "+_vm._s(item.icon)+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"white--text pl-2"},[_vm._v("\n              "+_vm._s(item.text)+"\n            ")])],1)})],2),_vm._v(" "),_c('button',{staticClass:"gala-btn full-width",on:{"click":function($event){_vm.showInstructionsDialog = false}}},[_vm._v("\n          "+_vm._s(_vm.$t('common.cta.close'))+"\n        ")])],1)],1)],1):_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{staticClass:"unselectable pt-6",attrs:{"xs12":""}},[_c('v-tooltip',{attrs:{"left":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({staticClass:"info-badge"},on),[_vm._v("\n            ?\n          ")])]}}])},[_vm._v(" "),_c('span',{staticClass:"white--text"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'components.newWallet.displayRecoveryPhrase.recoveryPhraseStatement'
            ))+"\n        ")])]),_vm._v(" "),_c('h1',{staticClass:"white--text notranslate",staticStyle:{"word-spacing":"22px"},attrs:{"translate":"no"}},[_vm._v("\n        "+_vm._s(_vm.mnemonic.join(' '))+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-center text-left mt-6"},[_c('div',{class:{ 'circle-check mr-3': true, checked: _vm.writtenDown },attrs:{"data-ua-interaction-target-type":"BUTTON","data-ua-interaction-target-id":"recorded-recovery-phrase-acknowledgement"},on:{"click":function($event){_vm.writtenDown = !_vm.writtenDown}}}),_vm._v(" "),_c('span',[_vm._v("\n        "+_vm._s(_vm.$t(
            'components.newWallet.displayRecoveryPhrase.recordedRecoveryPhrase'
          ))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"d-flex align-center text-left mt-2"},[_c('div',{class:{ 'circle-check mr-3': true, checked: _vm.doubleConfirm },attrs:{"data-ua-interaction-target-type":"BUTTON","data-ua-interaction-target-id":"recorded-recovery-phrase-second-acknowledgement"},on:{"click":function($event){_vm.doubleConfirm = !_vm.doubleConfirm}}}),_vm._v(" "),_c('span',[_vm._v("\n        "+_vm._s(_vm.$t(
            'components.newWallet.displayRecoveryPhrase.recordingConfirmation'
          ))+"\n      ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }