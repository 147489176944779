var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showInsufficientFunds)?_c('section',{class:{ 'insufficient-funds-list': true, drawer: _vm.bottomDrawer }},_vm._l((_vm.currenciesToShow),function(currency){return _c('div',{staticClass:"insufficient-fund py-4 px-6 mt-4"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"size":"14","color":"rgb(231, 29, 54)"}},[_vm._v("\n          mdi-alert-circle-outline\n        ")]),_vm._v(" "),_c('p',{staticClass:"mb-0 ml-2"},[_vm._v("\n          "+_vm._s(_vm.$t('components.wallet.insufficientFundsList.insufficient', {
              coin: _vm.filterOutNetwork(currency.wallet.symbol),
            }))+"\n        ")])],1),_vm._v(" "),_c('p',{staticClass:"mb-0 text-decoration-underline pointer",attrs:{"data-ua-interaction-target-type":"BUTTON","data-ua-interaction-target-id":("insufficient-funds_receive-" + (_vm.filterOutNetwork(
            currency.wallet.symbol
          )) + "-btn")},on:{"click":function($event){return _vm.goTo(("/balances/" + (currency.wallet.symbol) + "?modal=receive"))}}},[_vm._v("\n        "+_vm._s(_vm.$t('components.wallet.insufficientFunds.receiveFunds'))+"\n      ")])]),_vm._v(" "),(_vm.purchaseFundsLinks[currency.wallet.tokenId].route)?_c('button',{staticClass:"rounded-btn white-btn mx-auto mt-4",attrs:{"data-ua-interaction-target-type":"BUTTON","data-ua-interaction-target-id":("insufficient-funds_buy-" + (_vm.filterOutNetwork(
          currency.wallet.symbol
        )) + "-on-platform-btn")},on:{"click":function($event){return _vm.goTo(_vm.purchaseFundsLinks[currency.wallet.tokenId].route)}}},[_vm._v("\n      "+_vm._s(_vm.purchaseFundsLinks[currency.wallet.tokenId].cta)+"\n    ")]):(_vm.purchaseFundsLinks[currency.wallet.tokenId].link)?_c('a',{attrs:{"href":_vm.purchaseFundsLinks[currency.wallet.tokenId].link,"target":"_blank","data-ua-interaction-target-type":"BUTTON","data-ua-interaction-target-id":("insufficient-funds_buy-" + (_vm.filterOutNetwork(
          currency.wallet.symbol
        )) + "-on-external-site-btn")}},[_c('button',{staticClass:"rounded-btn white-btn mx-auto mt-4"},[_vm._v("\n        "+_vm._s(_vm.purchaseFundsLinks[currency.wallet.tokenId].cta)+"\n      ")])]):_vm._e()])}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }