




















import { Vue, Component, Prop } from 'vue-property-decorator';
import Checkbox from '~/components/UIComponents/Checkbox.vue';

@Component({
  components: { Checkbox },
})
export default class AgreementStatement extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly checkboxDisabled!: boolean;

  @Prop({ type: String })
  readonly agreementText!: string;

  @Prop({ type: Boolean, default: false })
  readonly value!: boolean;

  get localValue() {
    return this.value;
  }

  set localValue(isChecked: boolean) {
    this.$emit('input', isChecked);
  }

  onToggled(isChecked: boolean) {
    if (this.checkboxDisabled) return;
    this.localValue = isChecked;
  }
}
