















































































































































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { State, Mutation, Action } from 'vuex-class';
import { LinkIdentity } from '~/mixins';
import { setRedirectAfterLogin } from '~/utils/redirectAfterLogin';
import PlayOnTelegram from '~/components/Games/PlayOnTelegram.vue';
import ConvertGalaChainCurrency from '~/components/Wallet/ConvertGalaChainCurrency.vue';
import NewWallet from '~/components/NewWallet/NewWallet.vue';
import { IWallet } from '~/store/inventory/types';
import { getAvailableBalance } from '~/utils/checkForSufficientFunds';

@Component({
  components: { PlayOnTelegram, ConvertGalaChainCurrency, NewWallet },
})
export default class TrezAchievementPopover extends Mixins(LinkIdentity) {
  @Prop(Boolean) drawerControl!: boolean;

  @State(profile => profile.user.loggedIn, { namespace: 'profile' })
  loggedIn!: boolean;

  @State(profile => profile.user.hasGyriPassphrase, { namespace: 'profile' })
  hasGyriPassphrase!: boolean;

  @State(profile => profile.user.firstName, { namespace: 'profile' })
  firstName!: boolean;

  @State(profile => profile.user.lastName, { namespace: 'profile' })
  lastName!: boolean;

  @State('showTrezAchievementDialog')
  readonly showTrezAchievementDialogState!: boolean;

  @State('wallets', { namespace: 'inventory' })
  private wallets!: IWallet[];

  @Mutation toggleTrezAchievementDialog!: (payload?: boolean) => void;

  @Action('getWalletData', { namespace: 'inventory' })
  private getWalletData!: (coinSymbol: string) => void;
  @Action('getNewNotifications', { namespace: 'notifications' })
  private getNewNotifications!: () => void;

  showConvertView = false;
  showSetPassphrase = false;
  userSelectedAction: null | 'redeem_casino_credits' | 'mint' = null;

  get popoverFlagEnabled() {
    return process.env.showTrezAchievementPopover;
  }

  get descriptionText() {
    return !this.loggedIn
      ? this.$t('components.trezAchievementPopover.loginInstruction')
      : this.hasTelegramLinked && !this.hasGyriPassphrase
      ? this.$t('components.settings.gyriPassphrase.createPassphrase')
      : this.$t('components.trezAchievementPopover.convertDescription');
  }

  get hasTelegramLinked() {
    return !!this.auth0UserIdentities.find(i => i.connection === 'Telegram');
  }

  get activeStep() {
    if (!this.loggedIn || !this.hasTelegramLinked) {
      return 1;
    } else if (!this.hasGyriPassphrase) {
      return 2;
    }
    return 3;
  }

  get routeTriggersPopover() {
    return this.$route.query.modal === 'trez-popover';
  }

  get action() {
    return (
      this.userSelectedAction ??
      (this.$route.query.action === 'redeem-casino-credits'
        ? 'redeem_casino_credits'
        : 'mint')
    );
  }

  get loggedInViaTelegram() {
    return (
      this.loggedIn &&
      this.$auth.user['https://gala.games/connectionName'] === 'Telegram'
    );
  }

  get autoShowConvertView() {
    return (
      this.activeStep === 3 &&
      this.routeTriggersPopover &&
      this.hasGyriPassphrase
    );
  }

  get availableBalance() {
    if (this.allowanceWallet?.balance && this.allowanceWallet?.pendingBalance) {
      return getAvailableBalance(
        this.allowanceWallet.balance,
        this.allowanceWallet.pendingBalance,
        this.allowanceWallet.lockedBalance,
      );
    }

    return 0;
  }

  get allowanceWallet() {
    const trezAllowanceWallet = this.wallets.find(
      wallet => wallet.symbol === 'TREZ[GalaChainAllowance]',
    );

    if (trezAllowanceWallet) {
      const {
        receiveAddress,
        balance,
        contractAddress,
        coinPrice,
        canSendFunds,
        hideFiat,
        sendable,
        receivable,
        convertable,
        tokenId,
        isTreasureChest,
        canBridgeTo,
        canSwapTo,
        network,
        icon,
        ethereumContractType,
        symbol,
        hasFeeVesting,
      } = trezAllowanceWallet;

      return {
        address: receiveAddress,
        balance: +balance.confirmed,
        pendingBalance: +balance.unconfirmed,
        lockedBalance: +balance.locked,
        claimDetails: balance.claimDetails,
        exportableTo: balance.exportableTo,
        tokenId,
        contractAddress,
        coinPrice,
        canSendFunds,
        hideFiat,
        sendable,
        receivable,
        convertable,
        isTreasureChest,
        canBridgeTo,
        canSwapTo,
        network,
        icon,
        ethereumContractType,
        symbol,
        hasFeeVesting,
      };
    }
  }

  mounted() {
    if (this.loggedInViaTelegram || this.routeTriggersPopover) {
      this.toggleTrezAchievementDialog(true);
    }
  }

  closeConvertDialog() {
    this.toggleTrezAchievementDialog(false);
    this.showConvertView = false;
    this.showSetPassphrase = false;
  }

  goTo(route: string) {
    this.$router.push(route);
    this.toggleTrezAchievementDialog(false);
  }

  loginViaTelegram(signup: boolean) {
    if (!['/loggedin'].includes(this.$route.path)) {
      setRedirectAfterLogin(this.$route.fullPath);
    }

    const authorizationParams: any = {
      prompt: 'login',
      'ext-sso-order-first': 'Telegram',
    };

    if (signup) {
      authorizationParams.screen_hint = 'signup';
    }
    return this.$auth.loginWithRedirect({
      authorizationParams,
    });
  }

  onNewWalletSuccess() {
    this.refreshUser();
  }

  handleShowConvertView(action: 'mint' | 'redeem_casino_credits') {
    this.userSelectedAction = action;
    this.showConvertView = true;
  }

  handleConvertSuccess() {
    if (this.allowanceWallet?.symbol) {
      this.getWalletData(this.allowanceWallet.symbol);
      this.getNewNotifications();
    }
  }

  @Watch('autoShowConvertView', { immediate: true })
  handleAutoShowConvert(show: boolean) {
    if (show) {
      this.showConvertView = true;
    }
  }
}
